

export const Paths = {
  Dashboard: "/dashboard",
  DashboardUDC: "/UDC/dashboard",
  LogIn: "/login",
  SignUp: "/register",
  ForgotPassword: "/forgot-password",
  QuestListFirst: "/user-question/:id",
  ResetPassword: "/api/v1/users/password",
  MyAssessments: "/my-assessments",
  Certificate: "/certificates",
  LearningProgram: "/learning-programs",
  Messages: "/messages",
  Profile: "/profile",
  Home: "/",
  About: "/about",
  CompleteSignup: "/complete-signup",
  Admin: "/admin",
  GetUserInfo: "/get-user-info",
  UpdateUserProfile: "/updateuserprofile",
  Completed: "/completed",
  Instructions: '/instructions',
  Welcome: "/welcome",
  AdminReporting: "/reporting",
  StudentAssessment: "/web-assessment",
  UdcStudentAssessment: "/udc-web-assessment",
  UdcAssessementCompleted: "/completed",
  udcWelcome: "/udc-welcome",

  assessmentDashboardGames: '/assessment-dashboard/games/:game_name',

  upgradeDashboard: '/upgrade-dashboard',
  upgradeGamesSurvey: '/games-survey',
  upgradeSchedule: '/schedule',
  upgradeReports: '/reports',
  upgradeAdvisorsCourses: '/advisors-courses',
  UpgradeSettings: '/settings',
  UpgradeDemographics: '/demographics',
  upgradeIntroduction: '/survey-introduction',
  upgradeSurveyQuestions: '/survey-questions',
  upgradeDemographicsQuestions: '/demographics-questions',
  UpgradeFeedbackQuestions: '/feedback-questions',
  UpgradeContactUs: '/contact-us',
  GenericDashBoard: '/generic-dashboard',
  GenericGamesAndSurvey: '/generic-games-survey',
  GenericSchedule: '/generic-schedule',
  GenericReports: '/generic-reports',
  GenericAdvisorsCourses: '/generic-advisorsCourses',
  GenericSetting:'/generic-settings',
  GenericDemographics:'/generic-demographics',
  GenericIntroduction:'/generic-introduction',
  GenericQuestionnaire:'/generic-questionnaire',
  GenericFeedback:'/generic-feedback',
  GenericContactUs:'/generic-contact-us',
  GenericDemographicsQueslist:'/generic-demographic-questionnaire',
  GenericAllGames:'/generic-games-tab',
  GenericGamesStatusPage:'/generic-games-reports'
};
